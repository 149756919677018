$main_Color: #0d3b6d;

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $main_Color;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background: $main_Color;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $main_Color;
}

//Login
.login-page {
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  font-family: "Jost", sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: #1c4f85;

  .logo-div {
    text-align: center;
    margin-top: 20px;

    img {
      width: 175px;
    }
  }

  .main {
    width: 350px;
    height: 500px;
    margin: 100px auto;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 5px 20px 50px #000;

    #chk {
      display: none;

      &:checked ~ .password-remember {
        transform: translateY(-500px);
      }

      &:checked ~ .password-remember label {
        transform: scale(1);
        padding-top: 10px;
      }

      &:checked ~ .sign-in label {
        transform: scale(0.6);
      }
    }

    .sign-in {
      position: relative;
      width: 100%;
      height: 100%;
    }

    label {
      color: #fff;
      font-size: 1.8em;
      justify-content: center;
      display: flex;
      margin: 50px;
      font-weight: bold;
      cursor: pointer;
      transition: 0.5s ease-in-out;
    }

    input {
      width: 60%;
      height: 20px;
      justify-content: center;
      display: flex;
      margin: 20px auto;
      padding: 20px;
      border: none;
      outline: none;
      border-radius: 10px;
      border: 1px solid green;
      color: $main_Color;
    }

    button {
      width: 60%;
      height: 40px;
      margin: 10px auto;
      justify-content: center;
      display: block;
      color: #fff;
      background: #ec7424dd;
      font-size: 1em;
      font-weight: bold;
      margin-top: 20px;
      outline: none;
      border: none;
      border-radius: 5px;
      transition: 1s ease-in;
      cursor: pointer;

      &:hover {
        background: #ec7424;
      }
    }

    .password-remember {
      height: 460px;
      background: #eee;
      border-radius: 60% / 10%;
      transform: translateY(-180px);
      transition: 0.8s ease-in-out;

      label {
        color: #042e5a;
        transform: scale(0.6);
      }
    }
  }
}

//Header

.header {
  text-align: center;
  background-color: rgb(44, 93, 145);
  padding-block: 10px;

  img {
    width: 200px;
  }
}

//Navbar

#page-wrap {
  overflow: auto;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;

  &:hover {
    color: white;
  }
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 35px;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

// Form pages
.formPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  padding: 20px;

  div,
  form,
  input,
  label,
  textarea {
    padding: 0;
    margin: 0;
    outline: none;
    font-family: Roboto, Arial, sans-serif;
    font-size: 13px;
    color: #666;
    line-height: 22px;
  }

  legend {
    color: #fff;
    background-color: $main_Color;
    padding: 10px 15px;
    font-size: 20px;
  }

  h1 {
    position: absolute;
    margin: 0;
    font-size: 36px;
    color: #fff;
    z-index: 2;
  }

  form {
    width: 100%;
    padding: 20px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 0 20px 0 $main_Color;
  }

  .header-part {
    position: relative;
    height: 320px;
    background-image: url("./Images/loko.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &::after {
      background-color: rgba(0, 0, 0, 0.6);
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  input,
  textarea {
    width: calc(100% - 10px);
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  select {
    width: 100%;
    padding: 7px 0;
    background: transparent;
  }

  .item:hover p,
  .question:hover p,
  .question label:hover,
  input:hover::placeholder,
  textarea:hover::placeholder {
    color: $main_Color;
  }

  .item {
    position: relative;
    margin: 10px 0;

    input:hover,
    textarea:hover {
      border: 1px solid transparent;
      box-shadow: 0 0 6px 0 $main_Color;
      color: $main_Color;
    }

    h5 {
      color: #042d5a;
    }

    span {
      color: red;
    }
  }

  .btn-block {
    margin-top: 10px;
    text-align: center;
  }

  button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: #095584c6;
    transition: 1s;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
  }

  button:hover {
    background: $main_Color;
  }
}

//Homepage

.homePage {
  width: 95%;
  margin: 20px auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px 15px;
  grid-auto-rows: minmax(auto);

  //homePage card
  .card {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
    transition: 1s;
    color: rgb(4, 46, 90);
    border-radius: 30px;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
      cursor: pointer;
    }

    .card-body {
      width: 90%;
      margin-inline: auto;
      p {
        font-size: 25px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 0 !important;
      }
    }
  }
}

//Common Popup Card

.modal-header {
  border-bottom: 3px solid #042d5a;
  color: #363f4d;
  margin-bottom: 5px;
  width: 95%;
  padding: 5px 0;
  margin-inline: auto;

  button {
    font-size: 24px;
  }

  span {
    font-weight: 400;
    font-size: 22px;
    margin-top: 10px;
    width: 100%;
  }

  .btn-close {
    font-size: 24px;
  }
}

.card-popup-body-row {
  display: flex;
  width: 100%;

  div {
    width: 50%;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
      rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
      rgba(0, 0, 0, 0.09) 0px 32px 16px;
    padding: 20px;
    margin-top: 20px;

    h4 {
      color: #042d5a;
    }
  }
}

//Filter Part

.filter {
  .right-part-clear-date {
    color: white !important;
    text-align: center;
    background: #6d0d0dab;
    border: 1px solid #6d0d0dab;
    padding: 6px 10px;
    font-weight: 400;
    transition: 1s;
  }

  input {
    border: 1px solid #ccc;
    outline: none;
  }

  input:hover {
    border: 1px solid transparent;
    box-shadow: 0 0 6px 0 $main_Color;
  }

  .right-part-clear-date:hover {
    background: #6d0d0de3;
    border: 1px solid #6d0d0de3;
  }

  .right-part-button {
    color: white !important;
    text-align: center;
    background: #042e5abd;
    border: 1px solid #042e5abd;
    padding: 6px 10px;
    font-weight: 400;
    transition: 1s;
  }

  .right-part-button:hover {
    background: $main_Color;
    border: 1px solid $main_Color;
  }

  .right-part-button i {
    margin-right: 3.5px;
    font-size: 13px;
  }
}

//datatable
.datatable-page {
  width: 97%;
  margin: 20px auto;

  .add-button {
    color: white !important;
    text-align: center;
    background: #1d6902d4;
    border: 1px solid #1d6902d4;
    padding: 6px 10px;
    font-weight: 400;
    transition: 1s;
    margin-left: 1%;
    margin-bottom: 15px;
    border-radius: 5px;

    &:hover {
      background: #1d6902;
      border: 1px solid #1d6902;
    }
  }

  .user-list,
  .past-maintenance-list,
  .maintenance-list,
  .documents-list,
  .train-list {
    width: 98%;
    border: 0.5px outset rgb(4, 46, 90, 0.8);
    border-radius: 12px;
    margin-inline: auto;

    .part-header {
      padding: 20px;
      background: rgb(4, 46, 90, 0.8);
      border-radius: 10px;
      color: white;
      font-size: 18px;
    }

    .response-message{
      width: 98%;
      margin: 25px auto 0;
    }

    .part-body {
      width: 98%;
      margin: 25px auto;

      .delete-button {
        color: white !important;
        text-align: center;
        background: #dc3546cc;
        border: 1px solid #dc3546cc;
        padding: 6px 10px;
        font-weight: 400;

        &:hover {
          background: #dc3546;
          border: 1px solid #dc3546;
        }
      }

      .update-button {
        color: white !important;
        text-align: center;
        background: #ec7424d2;
        border: 1px solid #ec7424d2;
        padding: 6px 10px;
        font-weight: 400;

        &:hover {
          background: #ec7424;
          border: 1px solid #ec7424;
        }
      }

      .custom-select {
        margin-left: 0 !important;
        margin-bottom: 10px;
      }

      .tbody tr {
        display: table;
        table-layout: fixed;
      }

      td {
        vertical-align: middle;
      }
    }
  }
}

.footer {
  width: 100%;
  line-height: 50px;
  background-color: $main_Color;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;

  p {
    display: inline-block;
    padding-left: 100%;
    animation: footer 25s linear infinite;
    margin-bottom: 0;
    font-size: 14px;
  }

  @keyframes footer {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }
}

@media (max-width: 960px) {
  .homePage {
    grid-template-columns: repeat(2, 1fr);
  }

  //Common Popup Card

  .modal-header-part {
    border-bottom: 3px solid #042d5a;
    color: #363f4d;
    margin-bottom: 5px;
    width: 95%;
    padding: 5px 0;

    button {
      font-size: 24px;
    }
  }

  .modal-header-part-span {
    font-size: 20px;
  }

  .modal-body {
    font-size: 14px;

    h4 {
      font-size: 17px;
    }
  }

  .add-button,
  .right-part-clear-date,
  .right-part-button,
  .form-select {
    font-size: 13px;
  }

  .datatable-page {
    .part-body {
      overflow-y: scroll;
      font-size: 13px;

      .delete-button,
      .update-button {
        font-size: 13px;
        margin-bottom: 5px;
      }
    }
  }
}

@media (min-width: 568px) {
  .name-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    input,
    textarea {
      width: calc(50% - 20px);
    }

    div {
      width: calc(50% - 20px);
      input,
      textarea {
        width: 97%;
      }

      label {
        display: block;
        padding-bottom: 5px;
      }
    }
  }
}

@media (max-width: 568px) {
  .homePage {
    grid-template-columns: repeat(1, 1fr);
  }

  .pagination {
    width: 25%;
    margin: 10px auto !important;
  }

  .name-item {
    input:nth-child(2) {
      margin-top: 8px !important;
    }
  }
}
